import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

var config = {
	apiKey: "AIzaSyCWKCO99Pl0cUq4FBOGRYPxFjNNsXCZu5I",
	authDomain: "partypay-a3d12.firebaseapp.com",
	databaseURL: "https://partypay-a3d12.firebaseio.com",
	projectId: "partypay-a3d12",
	storageBucket: "partypay-a3d12.appspot.com",
	messagingSenderId: "286561888158",
	appId: "1:286561888158:web:21e057517e44b598"
};

const fb = firebase.initializeApp(config);
const db = fb.firestore();
const auth = fb.auth();
const storage = fb.storage();
const rtdb = fb.database();
const functions = fb.functions();
const functionsEU = fb.functions('europe-west1');
const fbauth = firebase.auth;
const fieldvalue =firebase.firestore.FieldValue;
const fieldpath = firebase.firestore.FieldPath;

export {
	db,
	auth,
	fbauth,
	storage,
	rtdb,
	functions,
	fieldvalue,
	fieldpath,
	functionsEU
};

