import React from "react";
import {Col, Row, Input} from 'reactstrap';	
import { Line } from 'react-chartjs-2';
import "moment/locale/nl";


class OmzetPerUur extends React.Component {
	render() {
		return (
			<Row style={{paddingTop: 35}}>
				<Col lg={12}>
					{this.props.stats !== 'loading' && this.state.showChart ?
					<div className="graph-display">
						<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
							<h2 className="font-light text-muted" style={{marginTop: 5, marginBottom: 30, paddingRight: 20, fontWeight: 900, fontSize: 20, color: '#212529',}}>{this.state.lineDataCat === 'tokens' ? 'Totalen per uur' : ''}{this.state.lineDataCat === 'lockers' ? 'Lockers per uur' : ''}{this.state.lineDataCat === 'tickets' ? 'Tickets per uur' : ''}</h2>	
							<Input type="select" className='minimal' style={{width: 180}} onChange={e => this.setState({lineDataCat: e.target.value},()=>this.getLineData(e))}>
								<option value='tokens' selected={this.state.lineDataCat === 'tokens'}>Muntjes</option>
								<option value='lockers' selected={this.state.lineDataCat === 'lockers'}>Kluisjes</option>
								<option value='tickets' selected={this.state.lineDataCat === 'tickets'}>Tickets</option>
							</Input>								
						</div>
						<div style={{height: 340}}>
							<div style={{position: 'absolute', width: '100%', paddingRight: 76}}>
								<div className="chart-wrapper" style={{width: '100%', margin: '0 auto', height: 340}}>
									<Line data={this.state.lineData} options={{
										elements: { line: { borderJoinStyle: 'round' } }, 
										tooltips: { backgroundColor: '#d12a5e', yPadding: 10, xPadding: 10, displayColors: false, bodyFontSize: 13, borderColor: '#fff', borderWidth: 2, titleFontSize: 14, mode: 'x-axis', callbacks: { label: (t, d) => { return '  ' + d.datasets[t.datasetIndex].label + ': ' + t.yLabel.toLocaleString("nl-NL",{style:"currency", currency:"EUR"})}}}, 
										maintainAspectRatio: false, 
										layout: { padding: { left: 0, right: 0, top: 15, bottom: 5 } }, 
										legend: { mode: 'index', intersect: false, display: true, labels: { fontFamily: "Kentledge" } }, 
										hover: { mode: 'index', intersect: false },
										scales: { 
										yAxes: [{ display: true, stacked: false, gridLines: { drawBorder: false, display: true, color: 'rgba(0,0,0,0.15)', borderDash: [4, 6] }, ticks: {padding: 20, display: true, maxTicksLimit: 5, fontFamily: "Kentledge", callback: (value, index, values) => { return value.toLocaleString("nl-NL",{style:"currency", currency:"EUR"})}}}], 
										xAxes: [{ gridLines: { display: false, color: 'rgba(0,0,0,0.1)', borderDash: [8, 4] }, ticks: { padding: 15, fontFamily: "Kentledge", fontSize: 11, fontStyle: "bold", callback: function(value) {return value + '-' + value.substring(0, 3) + '59'}},
										onClick: function(evt, element) {console.log('clicked')}
									}]}}}/>	
								</div>
							</div>
						</div>
					</div>
					:
					<div className="campaign ct-charts graph-display" style={{height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<div class="loading-spinner" style={{height: 50, width: 50}}></div>
					</div>
					}
				</Col>
			</Row>	
		);
	}
	
	state = {
		lineDataCat: 'tokens',
		showChart: false, 
		lineData: {}
	}
	
	defaultDataset = {
		borderWidth: 2, 
		pointRadius: 6, 
		pointHoverRadius: 10, 
		pointHitRadius: 10, 
		pointBorderColor: '#f6f6f6', 
		pointBorderWidth: 5
	};
	
	componentDidUpdate(prevProps) {if(this.props.stats !== prevProps.stats && this.props.stats !== 'loading'){this.getLineData()}}
	
	getLineData(){
		this.colorCounter = 0;
		this.setState({showChart: false},()=>{	
			if(this.state.lineDataCat === 'tokens'){
				var tokenStats = {}
				var dataSets = [];
				var labels = [];
				Object.values(this.props.stats).forEach(statsDate => {					
					if(statsDate.tokenverkoop){
						Object.keys(statsDate.tokenverkoop.omzetperuur).forEach(uur => {
							if(!('Online' in tokenStats)){tokenStats['Online'] = {}}
							tokenStats['Online'][uur] = (uur in tokenStats['Online'] ? tokenStats['Online'][uur] : 0) + statsDate.tokenverkoop.omzetperuur[uur];
							labels.push(uur);
						});
					}	
					if(statsDate.oplaadpunten){
						Object.keys(statsDate.oplaadpunten).forEach(oplaadpunt => {
							Object.keys(statsDate.oplaadpunten[oplaadpunt].omzetperuur).forEach(uur => {
								if(!(oplaadpunt in tokenStats)){tokenStats[oplaadpunt] = {}}
								tokenStats[oplaadpunt][uur] = (uur in tokenStats[oplaadpunt] ? tokenStats[oplaadpunt][uur] : 0) + statsDate.oplaadpunten[oplaadpunt].omzetperuur[uur];
								labels.push(uur);
							});
						});
					}					
				});
				labels = [...new Set(labels)];
				if(labels.length > 0 && labels.sort().slice(-1)[0] !== '23:00'){labels.push((parseFloat((labels.sort().slice(-1)[0]).split(":")[0]) + 1) + ':00')}				
				Object.keys(tokenStats).forEach(oplaadpunt => {
					var randcolor = this.getColor();
					var data = [];
					labels.sort().forEach(uur =>{if(tokenStats[oplaadpunt][uur]){data.push(tokenStats[oplaadpunt][uur])}else{data.push(0)}})
					dataSets.push({...this.defaultDataset, ...{label: oplaadpunt, backgroundColor: randcolor.substring(0, randcolor.length - 1) + ',.1)', borderColor: randcolor, data: data, pointBackgroundColor: randcolor}});
				});
			}			
			if(this.state.lineDataCat === 'tickets'){
				var ticketStats = {}
				var dataSets = [];
				var labels = [];
				var names = {};
				Object.values(this.props.stats).forEach(statsDate => {					
					if(statsDate.ticketverkoop){
						Object.keys(statsDate.ticketverkoop).forEach(ticket => {
							names[ticket] = statsDate.ticketverkoop[ticket].naam;
							Object.keys(statsDate.ticketverkoop[ticket].omzetperuur).forEach(uur => {
								if(!(ticket in ticketStats)){ticketStats[ticket] = {}}
								ticketStats[ticket][uur] = (uur in ticketStats[ticket] ? ticketStats[ticket][uur] : 0) + statsDate.ticketverkoop[ticket].omzetperuur[uur];
								labels.push(uur);
							});
						});
					}					
				});
				labels = [...new Set(labels)];
				if(labels.length > 0 && labels.sort().slice(-1)[0] !== '23:00'){labels.push((parseFloat((labels.sort().slice(-1)[0]).split(":")[0]) + 1) + ':00')}	
				Object.keys(ticketStats).forEach(ticket => {
					var randcolor = this.getColor();
					var data = [];
					labels.sort().forEach(uur =>{if(ticketStats[ticket][uur]){data.push(ticketStats[ticket][uur])}else{data.push(0)}})
					dataSets.push({...this.defaultDataset, ...{label: names[ticket], backgroundColor: randcolor.substring(0, randcolor.length - 1) + ',.1)', borderColor: randcolor, data: data, pointBackgroundColor: randcolor}});
				});
			}
			this.setState({lineData: {datasets: dataSets, labels: labels}},()=>this.setState({showChart: true}));
		});
	}	

	getColor = () => {
		this.colorCounter = this.colorCounter + 1;
		if(this.colorCounter === 1){return 'rgba(209,42,94)'}
		else if(this.colorCounter === 2){return 'rgba(67,98,212)'}
		else if(this.colorCounter === 3){return 'rgba(67,160,71)'}
		else if(this.colorCounter === 4){return 'rgba(208,154,24)'}
		else if(this.colorCounter === 5){return 'rgba(88,46,69)'}
		else if(this.colorCounter === 6){return 'rgba(221,70,74)'}
		else if(this.colorCounter === 7){return 'rgba(128,71,222)'}
		else if(this.colorCounter === 8){return 'rgba(72,208,145)'}
		else if(this.colorCounter === 9){return 'rgba(113,63,63)'}		
		else if(this.colorCounter === 10){return 'rgba(95,95,95)'}	
		else{return 'rgba(' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ')'}
	}	

	colorCounter = 0;
	
}

export default OmzetPerUur;



